/* eslint-disable no-console */

import { register } from 'register-service-worker'
import Swal from 'sweetalert2';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // Swal.fire({
      //   title: 'App is accessible Offline',
      //   text: 'You can use this app even without an internet connection.',
      //   icon: 'success',
      // });
    },
    registered() {
      // Swal.fire('Service worker has been registered.');
    },
    cached() {
      Swal.fire('Content has been cached for offline use.');
    },
    updatefound() {
      Swal.fire('New content is downloading.');
    },
    updated() {
      Swal.fire({
        title: 'New Content Available',
        text: 'Please HARD refresh to load the latest version.',
        icon: 'info',
      });
    },
    offline() {
      showOfflineMessage();
    },
    // online() {
    //   Swal.fire({
    //     title: 'Internet Connection works',
    //     text: 'App is running in online mode.',
    //     icon: 'warning',
    //   });
    // },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}


let offlineAlert;

function showOfflineMessage() {
  offlineAlert = Swal.fire({
    title: 'No Internet Connection',
    text: 'App is running in offline mode.',
    icon: 'warning',
    allowOutsideClick: false, // Prevent dismissing the alert by clicking outside
    showConfirmButton: true, // Show the "OK" button
  });
}

// Check network status periodically and show offline message if disconnected
setInterval(() => {
  if (!navigator.onLine && !offlineAlert) {
    showOfflineMessage();
  }
}, 5000); // Check every 5 seconds


// Listen for confirmation button click
document.addEventListener('click', function(event) {
  const target = event.target;
  // If the user clicks on the confirmation button and there is an offline alert shown
  if (target && target.classList.contains('swal2-confirm') && offlineAlert) {
    offlineAlert.close(); // Close the offline alert
    offlineAlert = true; // Reset the reference to the offline alert
  }
});
