import { createApp } from 'vue'
import App from './App.vue'
import AddToHomescreen from '@owliehq/vue-addtohomescreen';
import './registerServiceWorker'

const app = createApp(App);
app.use(AddToHomescreen, {
    buttonColor: 'blue',
});

app.mount('#app');
