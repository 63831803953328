<template>
  <div class="home">
    <h1>Todo List</h1>
    <div>
      <input v-model="newTodo.text" @keyup.enter="addTodo" placeholder="Add a new todo" />
      <br>
      <br>
      <input type="file" @change="handleImageUpload" accept="image/*" />
      <button @click="addTodo">Add</button>
    </div>
    <ul>
      <li v-for="todo in todos" :key="todo.id">
        <input type="checkbox" v-model="todo.completed" @change="saveTodos" />
        <img :src="todo.image" alt="Todo Image" width="50" height="50" v-if="todo.image" />
        <span :class="{ 'completed': todo.completed }">{{ todo.text }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newTodo: { text: '', image: null },
      todos: []
    };
  },
  mounted() {
    this.fetchTodos();
  },
  methods: {
    fetchTodos() {
      const storedTodos = localStorage.getItem('todos');
      if (storedTodos) {
        this.todos = JSON.parse(storedTodos);
      } else {
        this.todos = [
          { id: 1, text: 'Learn Vue.js', completed: false, image: null },
        ];
      }
    },
    addTodo() {
      if (this.newTodo.text.trim() === '') return;
      this.todos.push({
        id: this.todos.length + 1,
        text: this.newTodo.text,
        completed: false,
        image: this.newTodo.image
      });
      this.saveTodos();
      this.newTodo = { text: '', image: null };
    },
    saveTodos() {
      localStorage.setItem('todos', JSON.stringify(this.todos));
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.newTodo.image = reader.result;
        };
      }
    }
  }
};
</script>

<style scoped>
.completed {
  text-decoration: line-through;
  color: #41b383;
}
</style>
